import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { VideoBlog } from "../../../components/video";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const VidImg = require("../../../assets/img/integration/bamboohr/video_img_serviceplus.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/me_blog/1.png");
const image3 = require("../../../assets/images/me_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/me_blog/3.png");
const image6 = require("../../../assets/images/me_blog/4.png");
const image7 = require("../../../assets/images/me_blog/5.png");
const image8 = require("../../../assets/images/me_blog/6.png");
const image9 = require("../../../assets/images/me_blog/7.png");
const image10 = require("../../../assets/images/me_blog/8.png");
const image11 = require("../../../assets/images/me_blog/9.png");
const image12 = require("../../../assets/images/me_blog/10.png");
const image13 = require("../../../assets/images/me_blog/11.png");
const image14 = require("../../../assets/images/me_blog/12.png");
const image15 = require("../../../assets/images/me_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free ManageEngine ServiceDesk Chatbot Guide for your Business. Build ManageEngine ServiceDesk Chatbot for automated workplace support. "
        description="Build ManageEngine ServiceDesk Chatbot without any coding in minutes Workativ’s platform provides easy tools to quickly build your ManageEngine ServiceDesk chatbot and automate workplace support "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to ManageEngine ServiceDesk Plus
                            Chatbot 
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is ManageEngine ServiceDesk Plus Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does ManageEngine ServiceDesk Plus Chatbot
                            works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is ManageEngine ServiceDesk Plus Chatbot
                            used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best ManageEngine ServiceDesk Plus Chatbot
                            platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Smarter AI-powered ManageEngine automation with
                            ManageEngine Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. How to Build ManageEngine ServiceDesk Plus
                            Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Business benefits of ManageEngine ServiceDesk
                            Plus chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          In{" "}
                          <a href="https://www.personneltoday.com/hr/know-customers-better-staff/">
                            May 2016,
                          </a>{" "}
                          Adam Hale, now CEO of Sage People, stated that
                          “organizations invest millions in building their
                          customer engagement strategies, but rarely know much
                          about their employees.” This is now more relevant than
                          ever. 
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <a href="https://www.tlnt.com/a-great-employee-experience-makes-for-a-great-customer-experience/">
                            It is a no-brainer
                          </a>{" "}
                          that organizations that invest in employee engagement
                          see huge improvements to their customer experience.
                          Satisfied employees equal satisfied customers.
                          How/where can you start with improving employee
                          experience? {" "}
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is ManageEngine ServiceDesk Plus Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            You can simplify your IT & HR operations with the
                            ticketing system, self-service portal, and
                            knowledge-base that IT & HR support and service
                            management solutions that ManageEngine
                            ServiceDesk provides, but you can make your IT & HR
                            operations even more simpler and faster with
                            AI-powered contextual chatbots with automated
                            workflows that enable 24×7 conversational
                            self-service for your employees on your business’
                            communications hub like&nbsp;
                            <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                              Slack
                            </a>
                             or 
                            <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                              &nbsp;Microsoft Teams.
                            </a>
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does ManageEngine ServiceDesk Plus Chatbot
                            works?  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            ManageEngine ServiceDesk Plus is a game changer in
                            transitioning IT teams from day-to-day firefighting
                            to providing excellent customer service. It gives
                            high visibility and centralised management when
                            dealing with IT problems, ensuring that
                            organisations have minimal downtime.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/servicedesk-plus-chatbot">
                              ManageEngine ServiceDesk Plus chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with them as well as to extract
                            required data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with the  ManageEngine ServiceDesk Plus
                            chatbot, it can not only have those benefits, but
                            also extend the functionalities of ManageEngine
                            ServiceDesk Plus tool using advanced integration and
                            workflows.{" "}
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is ManageEngine ServiceDesk Plus chatbot used
                            for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            ManageEngine ServiceDesk Plus is a robust help desk
                            and asset management software that offers help desk
                            agents and IT managers with an effective framework
                            for monitoring and managing the assets and IT
                            requests made by users of an organization's IT
                            resources. The IT help desk plays an essential role
                            in the delivery of IT services. It is often the
                            first point of contact people have with IT Services
                            when something does not function as anticipated.
                            Help-seeking end-users have a single point of
                            contact at the IT help desk. Without this, a company
                            would likely incur losses owing to inefficiency
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Asset Management and IT Request Tracking are the two
                            primary objectives of ManageEngine ServiceDesk Plus.
                            Using the following ServiceDesk modules, technicians
                            and system administrators may quickly address
                            complicated problems, reducing end-user irritation
                            caused by a time-consuming issue-resolution
                            procedure. Using asset management, they may also
                            keep track of the organization's demands and
                            distribute resources proactively to the appropriate
                            users/departments, hence enhancing the
                            organization's productivity.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            A ManageEngine ServiceDesk Plus chatbot can be quite
                            helpful not just for your users but also for the
                            agents, as it helps automate user interactions on
                            repetitive tasks and helps your team focus on more
                            important and complex tasks that require human
                            touch.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            With{" "}
                            <a href="https://workativ.com/conversational-ai-platform/servicedesk-plus-chatbot">
                              ManageEngine ServiceDesk Plus chatbot
                            </a>
                             , you can effortlessly set up ManageEngine
                            ServiceDesk Plus workflow automations like: 
                          </p>
                          <ul
                            class="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>Creating a request </li>
                            <li> Updating a request </li>
                            <li>Retrieving details about a request</li>
                            <li> Retrieving a list of all requests </li>
                            <li>Deleting a request </li>
                            <li>Adding a request note </li>
                            <li>Updating a request note </li>
                            <li> Retrieving details about a request note </li>
                            <li>Retrieving a list of all request notes </li>
                            <li>Deleting a request note </li>
                          </ul>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best ManageEngine ServiceDesk Plus Chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant is an AI-powered no-code platform
                            for building contextual chatbots with automated
                            workflows for business apps. Workativ Assistant’s
                            chatbots can be easily added to you Slack/Microsoft
                            Teams workspace so that your employees can
                            self-serve their IT issues on-the-go, anytime. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            By integrating ManageEngine ServiceDesk Plus with
                            Workativ Assistant, you ensure your employees get
                            the IT/HR support they’d love. 
                          </p>
                        </div>
                        <NocodeWrapper />
                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered ManageEngine automations with
                            ManageEngine Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            With Workativ Assistant, you can effortlessly set up
                            ManageEngine ServiceDesk Plus workflow automations
                            like: 
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Creating a request 
                            </span>
                            — Navigating through the convoluted menus of a
                            self-service portal to create an IT request can be
                            frustrating for employees. Get rid of this by
                            offering your{" "}
                            <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                              employees conversational IT self-service
                            </a>{" "}
                            with Workativ Assistant’s chatbots. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Updating a request
                            </span>
                            — Workativ Assistant’s ManageEngine ServiceDesk Plus
                            chatbots allow your help desk agents to update the
                            status of an IT request from the comfort of their
                            chat channel. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving details about a request
                            </span>
                            — Allow your employees to conversationally retrieve
                            details about their request from ManageEngine
                            ServiceDesk Plus with Workativ Assistant’s chatbots.
                            Employees can know details like the status of their
                            request just by having a quick chat with a Workativ
                            Assistant chatbot. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving a list of all requests
                            </span>
                            — Enable your help desk agents to retrieve a list of
                            all the requests they got from employees to plan
                            their day out with{" "}
                            <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                              Workativ Assistant’s AI-powered
                              chatbots/conversational AI. 
                            </a>
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Deleting a request
                            </span>
                            — Deleting a request can never be this easier. In
                            case an employee accidentally created a request,
                            they can delete it tension-free by asking Workativ
                            Assistant’s chatbots to do it for them. 
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Adding a request note
                            </span>
                            — Help desk agents can add notes to requests easily
                            with Workativ Assistant’s chatbots so that whenever
                            they hand over a request to another agent, everyone
                            will be in the loop
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Updating a request note
                            </span>
                            — Update a request note just by telling Workativ
                            Assistant’s chatbots what to update. As simple as
                            that. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving details about a request note
                            </span>
                            — Allow help desk agents to retrieve details about a
                            request note from the comfort of their
                            Slack/Microsoft Teams channel with Workativ
                            Assistant’s chatbots there.   
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving a list of all request notes
                            </span>
                            — With Workativ Assistant’s chatbots, agents can
                            retrieve a list of all request notes with no hassle
                            or extra steps like logging in and out of their
                            ManageEngine ServiceDesk Plus portal.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Deleting a request note
                            </span>
                            — Agents can delete a request note if they’d want
                            just by conversing with Workativ Assistant’s
                            chatbots on their business’ chat hub.  These are
                            some of the things you can automate when you
                            integrate ManageEngine ServiceDesk Plus with
                            Workativ Assistant. Why stop here when you can do
                            more? 
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to Build ManageEngine ServiceDesk Plus Chatbot
                            Without coding
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name.    
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot
                          </h3>
                          <ExistingBlogCta
                            ContentCta="Auto-resolve 60% Employee ServiceDesk Queries."
                            ButtonText="Book a Demo"
                            isColor="white"
                            backgroundImage={cta_2}
                            mobileBackgroundImage={cta_2_mob}
                          />
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt ManageEngine ServiceDesk Plus
                            bot from the bot Marketplace    
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download ManageEngine
                            ServiceDesk Plus workflows
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a ManageEngine ServiceDesk Plus
                            app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download ManageEngine ServiceDesk Plus app
                            workflow from marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the ManageEngine ServiceDesk Plus
                            application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your ManageEngine ServiceDesk Plus
                            account    
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the ManageEngine
                            ServiceDesk Plus chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your ManageEngine ServiceDesk Plus chatbot    
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy ManageEngine ServiceDesk Plus chatbot
                            in your favourite chat channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-3"
                          ></img>
                        </div>

                        {/* Video section */}

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build ServiceDesk Chatbot with workflow
                              automation
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/M3zwQ1I24hw?autoplay=1%22"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0  col-xs-12 mb-0 market_wrapper_page"
                          id="section7"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of&nbsp; ManageEngine ServiceDesk
                            Plus chatbot &nbsp;with Workativ Assistant
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            The benefits of integrating ManageEngine ServiceDesk
                            Plus with Workativ Assistant are countless. Let’s
                            take a look at some of them.
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            1. Lesser waiting times, increased productivity 
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            No more repetitive calls/tickets to help desk or HR.
                            With Workativ assistant’s ManageEngine ServiceDesk
                            Plus chatbot, you make sure your employees don’t
                            have to wait on endless calls to get what they’re
                            looking for which in turn leads to your help desk
                            agents’/ HR personnel’s productivity increase,
                            letting them concentrate on the complex tasks at
                            hand that requires a human touch. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. 24×7, on-the-go, conversational IT/HR support 
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Since Workativ Assistant’s{" "}
                            <a href="https://workativ.com/conversational-ai-platform/servicedesk-plus-chatbot">
                              ManageEngine ServiceDesk Plus chatbot
                            </a>{" "}
                            will be added to your business’ Slack or Microsoft
                            Teams workspace, you enable your employees to take
                            care of their IT/HR needs themselves from the
                            comfort of their chat hub on their laptop/mobile
                            phone, on-the-go. They don’t have to log in multiple
                            times into static portals to do the same.   
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. Creating a synergy between existing apps   
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Like with the Active Directory ServiceDesk Plus
                            integration, Office 365 ServiceDesk Plus
                            integration, Jira ServiceDesk Plus integration, or
                            Outlook ServiceDesk Plus integration, you can
                            combine your other business apps creating an
                            automated workflow that does much more with those
                            apps in just a single run.
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            4. Better ROI   
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            By making Workativ Assistant’s chatbot a part of
                            your business’ internal IT support, you ensure that
                            you get the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                              best ROI on your business’ internal workplace
                              support
                            </a>{" "}
                            by deflecting unnecessary calls/tickets to help
                            desk/HR thereby improving overall employee morale. 
                          </p>
                        </div>

                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            In conclusion, the ManageEngine ServiceDesk Plus
                            Chatbot offers a number of benefits for companies
                            looking to improve customer service & employee
                            service.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Build an AI bot for your workplace in minutes with
                            no coding required using Workativ. Workativ is a
                            FREE no-code SaaS platform that enables businesses
                            to design, automate, and implement conversational AI
                            chatbots in under 60 minutes.
                            <a href="https://workativ.com/conversational-ai-platform/servicedesk-plus-chatbot">
                              &nbsp;Try our Servicedesk Plus chatbot for FREE.
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/get-over-the-pandemic-with-digital-assistants "
                              >
                                Get over the Pandemic standstill: Leapfrog with
                                Digital Assistants that enable business
                                continuity during COVID-19
                              </a>
                            </li>

                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                              >
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers "
                              >
                                Digital Workers to the rescue: Reasons why
                                Digital Transformations fail and how AI-Powered
                                Chatbots with Automated Workflows can help
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4 class="font-section-normal-text-medium">
        No Code - Free ServiceDesk Plus Chatbot
      </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="servicedesk plus chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
